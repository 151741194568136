var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-5",attrs:{"fluid":""}},[_c('v-tabs',{attrs:{"value":_vm.currentTab}},[_c('v-tab',{on:{"click":function($event){return _vm.toggleTab(0)}}},[_vm._v(" Отчёт ")]),_c('v-tab-item',[_c('v-row',{staticClass:"options",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-7",attrs:{"cols":"auto"}},[_c('p',{staticClass:"title"},[_vm._v("Отчёты")])]),_c('div',{staticStyle:{"width":"110px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('v-select',{attrs:{"items":_vm.paginationReports.limits,"hide-spin-buttons":"","hide-selected":"","hide-details":"","outlined":""},on:{"input":function($event){return _vm.getReport(1)}},model:{value:(_vm.paginationReports.limit),callback:function ($$v) {_vm.$set(_vm.paginationReports, "limit", $$v)},expression:"paginationReports.limit"}})],1)])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.canUser('report-c'))?_c('CreateReports',{on:{"refreshData":_vm.getReport}}):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"items-per-page":_vm.paginationReports.limit,"loading":_vm.loading,"loading-text":"Пожалуйста подождите, данные загружаются...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString("ru-RU"))+" "+_vm._s(new Date(item.created_at).toLocaleTimeString("ru-RU"))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updated_at).toLocaleDateString("ru-RU"))+" "+_vm._s(new Date(item.updated_at).toLocaleTimeString("ru-RU"))+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.fileURL(item.file),"download":item.filename}},[_vm._v("Скачать отчет")])]}}])}),_c('custom-pagination',{attrs:{"pagination":_vm.paginationReports},on:{"updatePage":function($event){return _vm.getReport($event)}}})],1),_c('v-tab',{on:{"click":function($event){return _vm.toggleTab(1)}}},[_vm._v(" Табель ")]),_c('v-tab-item',[_c('v-row',{staticClass:"options",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-7",attrs:{"cols":"auto"}},[_c('p',{staticClass:"title"},[_vm._v("Табель")])]),_c('div',{staticStyle:{"width":"110px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"input-wrapper"},[_c('v-select',{attrs:{"items":_vm.paginationTables.limits,"hide-spin-buttons":"","hide-selected":"","hide-details":"","outlined":""},on:{"input":function($event){return _vm.getTable(1)}},model:{value:(_vm.paginationTables.limit),callback:function ($$v) {_vm.$set(_vm.paginationTables, "limit", $$v)},expression:"paginationTables.limit"}})],1)])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.canUser('report-c'))?_c('TableReports',{on:{"refreshData":_vm.getTable}}):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tables,"items-per-page":_vm.paginationTables.limit,"loading":_vm.loading2,"loading-text":"Пожалуйста подождите, данные загружаются...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString("ru-RU"))+" "+_vm._s(new Date(item.created_at).toLocaleTimeString("ru-RU"))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.updated_at).toLocaleDateString("ru-RU"))+" "+_vm._s(new Date(item.updated_at).toLocaleTimeString("ru-RU"))+" ")]}},{key:"item.download",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":_vm.fileURL(item.file),"download":item.filename}},[_vm._v("Скачать табель")])]}}])}),_c('custom-pagination',{attrs:{"pagination":_vm.paginationTables},on:{"updatePage":function($event){return _vm.getTable($event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }