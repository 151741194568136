<template>
  <v-container fluid class="px-5">
    <v-tabs :value="currentTab">
      <v-tab @click="toggleTab(0)"> Отчёт </v-tab>
      <v-tab-item>
        <v-row class="options" align="center">
          <v-col cols="auto" class="pl-7">
            <p class="title">Отчёты</p>
          </v-col>
          <div style="width: 110px">
            <v-col cols="12">
              <div class="input-wrapper">
                <v-select
                  @input="getReport(1)"
                  :items="paginationReports.limits"
                  v-model="paginationReports.limit"
                  hide-spin-buttons
                  hide-selected
                  hide-details
                  outlined
                />
              </div>
            </v-col>
          </div>
          <v-spacer />
          <v-col cols="auto">
            <CreateReports
              @refreshData="getReport"
              v-if="canUser('report-c')"
            />
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="reports"
          :items-per-page="paginationReports.limit"
          :loading="loading"
          loading-text="Пожалуйста подождите, данные загружаются..."
          hide-default-footer
        >
          <template v-slot:item.fullName="{ item }">
            {{ item.user.first_name }} {{ item.user.last_name }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ new Date(item.created_at).toLocaleDateString("ru-RU") }}
            {{ new Date(item.created_at).toLocaleTimeString("ru-RU") }}
          </template>
          <template v-slot:item.updated_at="{ item }">
            {{ new Date(item.updated_at).toLocaleDateString("ru-RU") }}
            {{ new Date(item.updated_at).toLocaleTimeString("ru-RU") }}
          </template>
          <template v-slot:item.download="{ item }">
            <a :href="fileURL(item.file)" :download="item.filename"
              >Скачать отчет</a
            >
          </template>
        </v-data-table>
        <custom-pagination
          @updatePage="getReport($event)"
          :pagination="paginationReports"
        />
      </v-tab-item>
      <v-tab @click="toggleTab(1)"> Табель </v-tab>
      <v-tab-item>
        <v-row class="options" align="center">
          <v-col cols="auto" class="pl-7">
            <p class="title">Табель</p>
          </v-col>
          <div style="width: 110px">
            <v-col cols="12">
              <div class="input-wrapper">
                <v-select
                  @input="getTable(1)"
                  :items="paginationTables.limits"
                  v-model="paginationTables.limit"
                  hide-spin-buttons
                  hide-selected
                  hide-details
                  outlined
                />
              </div>
            </v-col>
          </div>
          <v-spacer />
          <v-col cols="auto">
            <TableReports @refreshData="getTable" v-if="canUser('report-c')" />
          </v-col>
        </v-row>
        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="tables"
          :items-per-page="paginationTables.limit"
          :loading="loading2"
          loading-text="Пожалуйста подождите, данные загружаются..."
          hide-default-footer
        >
          <template v-slot:item.fullName="{ item }">
            {{ item.user.first_name }} {{ item.user.last_name }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{ new Date(item.created_at).toLocaleDateString("ru-RU") }}
            {{ new Date(item.created_at).toLocaleTimeString("ru-RU") }}
          </template>
          <template v-slot:item.updated_at="{ item }">
            {{ new Date(item.updated_at).toLocaleDateString("ru-RU") }}
            {{ new Date(item.updated_at).toLocaleTimeString("ru-RU") }}
          </template>
          <template v-slot:item.download="{ item }">
            <a :href="fileURL(item.file)" :download="item.filename"
              >Скачать табель</a
            >
          </template>
        </v-data-table>
        <custom-pagination
          @updatePage="getTable($event)"
          :pagination="paginationTables"
        />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import CustomPagination from "@/components/CustomPagination";
import CreateReports from "@/components/Reports/CreateReports";
import TableReports from "@/components/Reports/TableReports";

export default {
  name: "Reports",
  components: { TableReports, CreateReports, CustomPagination },
  computed: {
    currentTab() {
      return parseInt(localStorage.getItem("currentTab"));
    },
  },
  data: () => {
    return {
      loading: true,
      loading2: true,
      reports: [],
      tables: [],
      headers: [
        {
          text: "Модуль",
          align: "start",
          sortable: false,
          value: "module",
        },
        {
          text: "ФИО",
          align: "start",
          sortable: true,
          value: "fullName",
        },
        {
          text: "Название Файла",
          align: "center",
          sortable: false,
          value: "filename",
        },
        {
          text: "Дата создания",
          align: "center",
          sortable: false,
          value: "created_at",
        },
        {
          text: "Статус",
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: "Модуль",
          align: "center",
          sortable: false,
          value: "module",
        },
        {
          text: "Действия",
          align: "center",
          sortable: false,
          value: "download",
        },
      ],
      paginationReports: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      paginationTables: {
        limit: 10,
        limits: [10, 50, 100],
        offset: 0,
        count: 0,
        page: 1,
        pages: 1,
      },
      search: "",
      // currentTab: 0,
    };
  },

  methods: {
    async getReport(page = 1) {
      this.loading = true;
      this.reports = [];
      this.paginationReports.page = page;
      this.paginationReports.offset =
        (this.paginationReports.page - 1) * this.paginationReports.limit;
      try {
        const response = await this.$axios.get("reports/", {
          params: {
            limit: this.paginationReports.limit,
            offset: this.paginationReports.offset,
          },
        });
        this.reports = response.data.results;
        this.loading = false;
        this.paginationReports.count = response.data.count;
        this.paginationReports.pages =
          Math.floor(
            this.paginationReports.count / this.paginationReports.limit
          ) + 1;
      } catch (e) {
        this.loading = false;
        this.$root.$emit("showDefaultError");
      }
    },
    async getTable(page = 1) {
      this.loading2 = true;
      this.tables = [];
      this.paginationTables.page = page;
      this.paginationTables.offset =
        (this.paginationTables.page - 1) * this.paginationTables.limit;
      try {
        const response = await this.$axios.get("reports/tables/", {
          params: {
            limit: this.paginationTables.limit,
            offset: this.paginationTables.offset,
          },
        });
        this.tables = response.data.results;
        this.loading2 = false;
        this.paginationTables.count = response.data.count;
        this.paginationTables.pages =
          Math.floor(
            this.paginationTables.count / this.paginationTables.limit
          ) + 1;
      } catch (e) {
        this.loading2 = false;
        this.$root.$emit("showDefaultError");
      }
    },
    fileURL(filePath) {
      return filePath.replace("http", "https");
    },
    toggleTab(value) {
      localStorage.setItem("currentTab", value);
    },
  },
  created() {
    this.getReport();
    this.getTable();
    if (!localStorage.getItem("currentTab")) {
      localStorage.setItem("currentTab", "0");
    }
  },
};
</script>

<style scoped>
.theme--light.v-tabs-items {
  background-color: inherit;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: inherit;
}
</style>
